<template>
  <div class="PrimeiraSecao desktop">
    <div class="container">
      <div class="row espaco d-flex align-items-center">
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 align-itens-center">
          <span class="titulo" :class="{textobranco: verdadeiro}" id="tituloprimeira">
            Comece a lucrar com anúncios e vendas na  internet.
          </span>
          <span :class="{textobranco: verdadeiro}" id="subtituloprimeira">
            Acelere o fluxo de caixa enquanto conquista novos<br>
            clientes com o Método MB.<br><br>

            Fature ao máximo vendendo para quem<br>realmente quer o seu produto.
          </span><br><br><br>
          <BotaoSite @click="mostrar" calltoaction="Quero vender + na internet"/>
        </div>
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 justify-content-end">
            <img id="imgprimeira" src="@/assets/img/1/foto.webp">
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex justify-content-center bilhao">
                 <button @click="mostrar" class="callbilhao">{{callbilhao}}</button>
            </div>
        </div>
      </div>
    </div>
  
  </div>

  <div class="PrimeiraSecao mobile">
    <div class="container">
      <div class="row espaco d-flex align-items-center">
        <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6 text-center">
          <span class="titulo" :class="{textobranco: verdadeiro}" id="tituloprimeira">
            Comece a lucrar com anúncios e vendas na  internet.
          </span>
          <span :class="{textobranco: verdadeiro}" id="subtituloprimeira">
            Acelere o fluxo de caixa enquanto conquista novos
            clientes com o Método MB.<br><br>

            Fature ao máximo vendendo para quem<br>realmente quer o seu produto.
          </span><br>
          <center><BotaoSite @click="mostrar" calltoaction="Quero vender + na internet"/></center>
        </div>
        <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6 justify-content-end">
            <img id="imgprimeira" src="@/assets/img/1/foto.webp">
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex justify-content-center bilhao">
                 <button @click="mostrar" class="callbilhao">{{callbilhao}}</button>
            </div>
        </div>
      </div>
    </div>
  
  </div>
   
  <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />

</template>

<script>
// @ is an alias to /src
import BotaoSite from '@/components/BotaoSite.vue'
import Popup from '@/components/Popup.vue'

export default {
  name: 'PrimeiraSecao',
  data: () => ( {
    verdadeiro: true,
    callbilhao: '+ de 1.8 bilhão de conversões realizadas',
    showDialog: false
  }),
   props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
  components: {
    BotaoSite,
    Popup
  }
}
</script>

<style scoped>


/* media css */

.textobranco {
  color: #fff;
}

#tituloprimeira {
  margin-bottom: 25px;
  display: block;
}

#subtituloprimeira {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.espaco {
  padding-top: 0%;
}

.PrimeiraSecao {
    height: 110vh;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
    padding: 200px 0px;
    margin-top: -121px;
}

img#imgprimeira {
    width: 100%;
    height: auto;
}
button.callbilhao {
    width: 463px;
    height: 53px;
    background-color: #491C77;
    border-radius: 134px;
    color: #fff;
    border: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-transform: uppercase;
}

.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.d-flex.justify-content-center.bilhao {
    margin-top: -51px;
}


@media (max-width: 2000px) { 



  button.botaoPadrao {
    background-color: #00C02A;
    width: 270px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}

button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 270px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1440px) { 
  .PrimeiraSecao {
    height: 938px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 

  .PrimeiraSecao {
    height: 863px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

  .PrimeiraSecao {
    height: 785px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}

  #subtituloprimeira {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 

  #subtituloprimeira {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 991.98px) { 

  .PrimeiraSecao {
    height: 692px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
}
#subtituloprimeira {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
 }

@media (max-width: 767.98px) { 

  .PrimeiraSecao {
    height: 760px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}

  button.botaoPadrao {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    margin: 30px 0px;
}

button.botaoPadrao:hover {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    margin: 30px 0px;
}

  .titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
    text-align: center;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

/* 1 */

.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
    text-align: center;
}
#subtituloprimeira {
    font-size: 15px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.d-flex.justify-content-center.bilhao {
    margin-top: 50px;
}
img#imgprimeira {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    top: 43px;
}

.PrimeiraSecao {
    height: 720px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}
button.callbilhao {
    width: 279px;
    height: 48px;
    background-color: #491C77;
    border-radius: 134px;
    color: #fff;
    border: 0px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
}

 }

@media (max-width: 360px) { 

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
/* 1 */

.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
    text-align: center;
}
#subtituloprimeira {
    font-size: 15px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    display: block;
}
.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.d-flex.justify-content-center.bilhao {
    margin-top: 50px;
}
img#imgprimeira {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    top: 43px;
}
.PrimeiraSecao {
    height: 720px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}
button.callbilhao {
    width: 279px;
    height: 48px;
    background-color: #491C77;
    border-radius: 134px;
    color: #fff;
    border: 0px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
}
 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}

/* 1 */

.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
    text-align: center;
}
#subtituloprimeira {
    font-size: 15px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
    display: block;
}
.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.d-flex.justify-content-center.bilhao {
    margin-top: 50px;
}
img#imgprimeira {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    top: 43px;
}
.PrimeiraSecao {
    height: 720px;
    background-image: url('@/assets/img/1/01.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-bottom-left-radius: 200px;
}
button.callbilhao {
    width: 279px;
    height: 48px;
    background-color: #491C77;
    border-radius: 134px;
    color: #fff;
    border: 0px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
}
 }


</style>