<template>
<div class="container">
   <div class="row text-center d-flex justify-content-end" style="width: 100%; margin-bottom: 100px; margin-top: 100px;">
      <div class="col-4 col-sm-4 coluna-one" style="">
             <span class="valor">18,7 B</span>
          <div class="row">
            <div class="col-6 col-sm-2 col-md-2 col-xl-4 col-lg-4 coluna-two onze">
              <span class="ano">2011</span>
            </div>
          </div>
      </div>
       <div class="col-6 col-sm-2 col-sm-2 col-md-2 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">22,5 B</span>
          <div class="row">
            <div class="col-6 coluna-two doze">
              <span class="ano">2012</span>
            </div>
          </div>
      </div> <!---->
       <div class="col-2 col-sm-2 col-sm-2 col-md-2 col-xl-4 col-lg-4 coluna-one">  <!---->
             <span class="valor">28,8 B</span>
          <div class="row">
            <div class="col-6 coluna-two treze">
              <span class="ano">2013</span>
            </div>
          </div>
      </div> <!---->
       <div class="col-6 col-sm-6 col-sm-2 col-md-6 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">35,8 B</span>
          <div class="row">
            <div class="col-6 coluna-two quatorze">
              <span class="ano">2014</span>
            </div>
          </div>
      </div><br><!---->
       <div class="col-6 col-sm-6 col-sm-2 col-md-6 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">41,3 B</span>
          <div class="row">
            <div class="col-6 coluna-two quinze">
              <span class="ano">2015</span>
            </div>
          </div>
      </div><br>
       <div class="col-6 col-sm-6 col-sm-2 col-md-6 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">44,4 B</span>
          <div class="row">
            <div class="col-6 coluna-two dezesseis">
              <span class="ano">2016</span>
            </div>
          </div>
      </div>
       <div class="col-6 col-sm-6 col-sm-2 col-md-6 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">47,7 B</span>
          <div class="row">
            <div class="col-6 coluna-two dezessete">
              <span class="ano">2017</span>
            </div>
          </div>
      </div>
       <div class="col-6 col-sm-6 col-sm-2 col-md-2 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">53,2 B</span>
          <div class="row">
            <div class="col-6 coluna-two dezoito">
              <span class="ano">2018</span>
            </div>
          </div>
      </div>
       <div class="col-6 col-sm-6 col-sm-2 col-md-2 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">61,9 B</span>
          <div class="row">
            <div class="col-2 coluna-two dezenove">
              <span class="ano">2019</span>
            </div>
          </div>
      </div>
      <div class="col-6 col-sm-6 col-sm-2 col-md-2 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">38,7 B</span>
          <div class="row">
            <div class="col-2 coluna-two vinte">
              <span class="ano">2020</span>
            </div>
          </div>
      </div>
      <div class="col-6 col-sm-6 col-sm-2 col-md-2 col-xl-4 col-lg-4 coluna-one"> <!---->
             <span class="valor">46,4 B</span>
          <div class="row">
            <div class="col-2 coluna-two vinteone">
              <span class="ano">2021</span>
            </div>
          </div>
      </div>
      <div class="text-fonte">
           <span class="fonte">Fontes: Webshoppers e Neotrust</span>
      </div>
     
		</div>
</div>      
</template>

<script>


export default {
    name: 'Charts',
}
</script>

<style>

.onze:hover:after {
  content: '18,7 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}

.doze:hover:after {
  content: '22,5 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.treze:hover:after {
  content: '28,8 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.quatorze:hover:after {
  content: '35,8 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}

.quinze:hover:after {
  content: '41,3 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.dezesseis:hover:after {
  content: '44,4 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}

.dezessete:hover:after {
  content: '47,7 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.dezoito:hover:after {
  content: '53,2 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.dezenove:hover:after {
  content: '61,9 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.vinte:hover:after {
  content: '38,7 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.vinteone:hover:after {
  content: '46,4 Bilhões';
  color: #000;
  position: absolute;
  transition: 2s;
  left: 70px;
  background: #fff;
  padding: 20px;
  z-index: 3;
  bottom: 58px;
  border-radius: 4px;
}
.onze:hover {
  transition: 2s;
}

@media (max-width: 2000px) { 

  .coluna-one {
      width: 70px; background-color: #D1A4FF; height: 306px; padding-top: 20px; margin-right: 40px;
  }

  .coluna-two {
    width: 70px; background-color: #8E4AD3; height: 57px; display: block;
    position: relative;
    top: 225px; padding-top: 5px;
  }

  .onze {
    padding-top: 14px;
  }

  .doze {
    height: 84px;
    display: block;
    position: relative;
    top: 198px;
    padding: 39px 0px 0px 0px;
}
.treze {
    height: 98px;
    display: block;
    position: relative;
    top: 184px;
    padding-top: 53px;
}

.quatorze {
    height: 125px;
    display: block;
    position: relative;
    top: 158px;
    padding-top: 78px;
}

.quinze {
    height: 145px;
    display: block;
    position: relative;
    top: 138px;
    padding-top: 97px;
}
.dezesseis {
    height: 161px;
    display: block;
    position: relative;
    top: 122px;
    padding-top: 110px;
}

.dezessete {
    height: 182px;
    display: block;
    position: relative;
    top: 100px;
    padding-top: 131px;
}

.dezoito {
    height: 200px;
    display: block;
    position: relative;
    top: 82px;
    padding-top: 148px;
}

.dezenove {
    height: 216px;
    display: block;
    position: relative;
    top: 66px;
    padding-top: 163px;
}
.vinte {
    height: 149px;
    display: block;
    position: relative;
    top: 133px;
    padding-top: 94px;
}
.vinteone {
    height: 170px;
    display: block;
    position: relative;
    top: 111px;
    padding-top: 115px;
}

span.fonte {
    color: #fff;
    font-size: 14px;
    top: 40px;
    position: relative;
    text-align: right;
    display: block;
    line-height: 16px;
    font-weight: 500;
    right: 26px;
}

}

@media (max-width: 1440px) {


 }

 @media (max-width: 1399px) {

    .coluna-one {
    width: 70px;
    background-color: #D1A4FF;
    height: 306px;
    padding-top: 20px;
    margin-right: 26px;
}

 }

 @media (max-width: 1199px) {
   .coluna-one {
    width: 70px;
    background-color: #D1A4FF;
    height: 306px;
    padding-top: 20px;
    margin-right: 9px;
 }
 }

 @media (max-width: 991px) {
   .coluna-one {
    width: 70px;
    background-color: #D1A4FF;
    height: 306px;
    padding-top: 20px;
    margin-right: 37px;
}
 }

span.valor {
    color: #491C77;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
}
span.ano {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
}

</style>