<template>
  <div class="QuintaSecao desktop">
    <div class="container-fluid">
      <div class="row row-programs">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span class="tituloQuinta">
              Faça da internet a sua<br> vendedora campeã!
          </span>
          <p class="subtituloquinta">
            Multiplique as vendas e encontre novos clientes todos os dias. 
          </p>
        </div>
        <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-program" id="colorback" v-for="net in internet" :key="net.id"
        :style="`background-image: url(${net.imagem})`">
            <div class="overlay">

            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="QuintaSecao mobile">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span class="tituloQuinta">
              Faça da internet a sua<br> vendedora campeã!
          </span>
          <p class="subtituloquinta">
            Multiplique as vendas e encontre novos clientes todos os dias. 
          </p>
          <center><img class="multiplique" src="@/assets/img/5/05mobile.jpg"></center>
          <img class="vetor" src="@/assets/img/6/icon.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'QuintaSecao',
  data: () => ({
    internet: [
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/noteboook.jpg'},
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/monitoor.jpg'},
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/blackk.jpg'},
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/caard.jpg'},
    ],
  }),
  components: {
  
  }
}
</script>

<style scoped>


/* media css */

@media (max-width: 2000px) { 

  .row-programs .col-program:hover {
  margin-top: -10px;
  background-color: #fafafa;
  transition: all 0.4s ease-in-out;
  zoom: 1.1;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    span.tituloQuinta {
    font-size: 36px;
    line-height: 46px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p.subtituloquinta {
    font-size: 20px;
    line-height: 25px;
    display: block;
    text-align: center;
    padding: 30px 0px;
}

.QuintaSecao {
    height: 1000px;
    background-image: url('@/assets/img/5/curvafundo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    padding: 0px 0px 100px 0px;
    z-index: 1;
    position: relative;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 803px;
    height: auto;
}
div#colorback {
    background-repeat: no-repeat;
    height: 468px;
    background-size: cover;
    padding: 0px;
    filter: blur(4px);
    transition: .5s;
}

div#colorback:hover {
    background-repeat: no-repeat;
    height: 445px;
    background-size: cover;
    padding: 0px;
    filter: blur(0px);
    transition: .5s;
}

div#colorback:hover .overlay {
    background-repeat: no-repeat;
    height: 445px;
    background-size: cover;
    background-color: #d1a4ff47;
    transition: .5s;
    width: 100%;
    padding: 0px;
}

 }

@media (max-width: 1440px) { 

  .QuintaSecao {
    height: 1000px;
    background-image: url('@/assets/img/5/curvafundo.png');
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: center left;
    margin-top: 0px;
    padding-top: 40px;
}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 803px;
    height: auto;
}

img.multiplique {
    width: 361px;
    height: 354px;
}
div#colorback {
    background-repeat: no-repeat;
    height: 333px;
    background-size: cover;
    padding: 0px;
    filter: blur(4px);
    transition: .5s;
}

div#colorback:hover {
    background-repeat: no-repeat;
    height: 340px;
    background-size: cover;
    padding: 0px;
    filter: blur(0px);
}

div#colorback:hover .overlay {
    background-repeat: no-repeat;
    height: 340px;
    background-size: cover;
    background-color: #d1a4ff47;
    transition: .5s;
    width: 321px;
    padding: 0px;
    filter: blur(0px);
}

 }

@media (max-width: 1399px) { 

  div#colorback {
    background-repeat: no-repeat;
    height: 330px;
    background-size: cover;
    padding: 0px;
    filter: blur(4px);
    transition: .5s;
}
div#colorback:hover {
    background-repeat: no-repeat;
    height: 330px;
    background-size: cover;
    padding: 0px;
    filter: blur(0px);
}

div#colorback:hover .overlay {
    background-repeat: no-repeat;
    height: 329px;
    background-size: cover;
    background-color: #d1a4ff47;
    transition: .5s;
    width: 307px;
    padding: 0px;
}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1199.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1024px) { 

  .QuintaSecao {
    height: 1000px;
    background-image: url('@/assets/img/5/curvafundo.png');
    background-repeat: no-repeat;
    background-size: 39%;
    background-position: center left;
    margin-top: 0px;
    padding-top: 40px;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

div#colorback {
    background-repeat: no-repeat;
    height: 243px;
    background-size: cover;
    padding: 0px;
    filter: blur(4px);
    transition: .5s;
}
div#colorback:hover {
    background-repeat: no-repeat;
    height: 241px;
    background-size: cover;
    padding: 0px;
    filter: blur(0px);
}
div#colorback:hover .overlay {
    background-repeat: no-repeat;
    height: 242px;
    background-size: cover;
    background-color: #d1a4ff47;
    transition: .5s;
    width: 229px;
    padding: 0px;
}

 }

@media (max-width: 991.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    div#colorback {
    background-repeat: no-repeat;
    height: 236px;
    background-size: cover;
    padding: 0px;
}
div#colorback:hover {
    background-repeat: no-repeat;
    height: 236px;
    background-size: cover;
    padding: 0px;
    filter: blur(0px);
}
div#colorback:hover .overlay {
    background-repeat: no-repeat;
    height: 236px;
    background-size: cover;
    background-color: #d1a4ff47;
    transition: .5s;
    width: 337px;
    padding: 0px;
}

 }

@media (max-width: 768px) { 

  .QuintaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 100px 0px;
    z-index: 1;
    position: relative;
}

img.vetor {
    padding: 50px 0px 0px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: auto;
}

  img.multiplique {
    width: 361px;
    height: auto;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

  .QuintaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 0px 0px;
    margin-top: 50px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloQuinta {
    font-size: 23px;
    line-height: 31px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

img.multiplique {
    width: 100%;
}
 }

@media (max-width: 360px) { 

  .QuintaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 0px 0px;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloQuinta {
    font-size: 23px;
    line-height: 31px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
img.multiplique {
    width: 100%;
}
 }
@media (max-width: 320px) { 

  .QuintaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 0px 0px;
}

.desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloQuinta {
    font-size: 23px;
    line-height: 31px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
img.multiplique {
    width: 100%;
}
 }

</style>