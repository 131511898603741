<template>
<Suspense>
  <template #default>
      <Carousel class="carouse" :autoplay="4500" :transition="5000" :itemsToShow="3.95" :breakpoints="breakpoints" :wrapAround="true">
        <template #addons>
          <center><Pagination /></center>
        </template>
        <Slide v-for="slide in slides" :key="slide">
          <div class="carousel__item">
          <img class="imgs" :src="slide.imagem">
          </div>
        </Slide>
      </Carousel>
    </template>
    <template #fallback>
      <div>Carregando...</div>
    </template>
  </Suspense>
</template>
<script>

import { Carousel, Pagination, Slide, transition } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
  data: () => ({
      slides: [
        {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/suellen.webp'},
        {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/testimonialvinicius.webp'},
         {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/rodrigo.webp'},
         {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/camila.webp'},
         {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/marcelo.svg'},
      ],

     breakpoints: {
      320: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      360: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      700: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
     768: {
        itemsToShow: 4,
        snapAlign: 'center',
      },

  }

  }),
  
  components: {
    Pagination,
     Carousel,
    Slide,
    transition, 
    
  },

};
</script>

<style scoped>





.carousel__slide {
    height: 555px;
    scroll-snap-stop: auto;
    flex-shrink: 0;
    margin: 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.imgs {
    width: 100%;
    padding: 0px 30px;
}

.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.5);
}

@media (max-width: 768px) {
  img.imgs {
    width: 68%;
    padding: 0px 30px;
}
}

@media (max-width: 575px) {
  img.imgs {
    width: 76%;
    padding: 0px 30px;
}
}

@media (max-width: 360px) {
  img.imgs {
    width: 76%;
    padding: 0px 30px;
}
}

</style>