<template>
    <div class="NonaSecao desktop">
        <div class="container">
            <div class="row">
               <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center justify-content-lg-center">
                    <BotaoSite @click="mostrar" calltoaction="QUERO FAZER MINHA EMPRESA CRESCER TAMBÉM" />
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <p class="depoimentos">Depoimentos</p>
                    <Slider />
                </div>
            </div>
        </div>
    </div>

    <div class="NonaSecao mobile">
        <div class="container">
            <div class="row">
               <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <center><BotaoSite @click="mostrar" calltoaction="QUERO FAZER MINHA EMPRESA CRESCER TAMBÉM" /></center>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <p class="depoimentos">Depoimentos</p>
                    <Slider />
                </div>
            </div>
        </div>
    </div>
    <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />
</template>

<script>
import Slider from '@/components/Slider.vue'
import BotaoSite from './BotaoSite.vue';
import Popup from '@/components/Popup.vue'
export default {
    name: 'NonaSecao',
    data: () => ({
        showDialog: false
    }),
    props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
    components: {
    Slider,
    BotaoSite,
    Popup
}
}
</script>

<style scoped>

@media (max-width: 2000px) { 

  button.botaoPadrao {
    background-color: #00C02A;
    width: 429px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 429px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

.NonaSecao {
    height: auto;
    padding: 0px 0px;
    padding-top: 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -20px;
}

p.depoimentos {
    color: #2C2C2C;
    font-size: 36px;
    line-height: 43px;
    font-weight: 700;
    text-align: center;
    margin-top: 100px;
}

 }

@media (max-width: 1440px) { 
  
 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 991.98px) { 

  button.botaoPadrao {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    position: relative;
    left: 157px;
  
}

  button.botaoPadrao:hover {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    position: relative;
    left: 157px;
}
 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 768px) { 

  button.botaoPadrao {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    margin-top: 0px;
    position: relative;
    left: 0px;
}

button.botaoPadrao:hover {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    margin-top: 0px;
    position: relative;
    left: 0px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

  .NonaSecao {
    height: auto;
    padding: 0px 0px;
    padding-top: 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -20px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

button.botaoPadrao {
    background-color: #0e992c;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #0e992c;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}

 }

@media (max-width: 360px) { 

   .NonaSecao {
    height: auto;
    padding: 0px 0px;
    padding-top: 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -20px;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
button.botaoPadrao {
    background-color: #0e992c;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #0e992c;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}
 }
@media (max-width: 320px) { 

   .NonaSecao {
    height: auto;
    padding: 0px 0px;
    padding-top: 0px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -20px;
}

.desktop {
    display: none;
}

.mobile {
  display: block;
}
button.botaoPadrao {
    background-color: #0e992c;
    width: 100%;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #0e992c;
    width: 100%;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}
 }


</style>