<template>
  <div class="quartaSecao desktop">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
             <img id="mascara" src="@/assets/img/4/mask.webp">
        </div>
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
          <span class="anuncio">
            AQUI SEU
          </span>
          <p class="desktop">
            <img id="funciona" src="@/assets/img/4/degrade.jpg" >
          </p><br><br>
          <p class="dados">
            Por meio de dados, criatividade e inteligência artificial, o Método MB aplica sete ações de marketing digital que resultam em mais conversões e aumentam a presença online da sua marca.
          </p>
          <p class="contatoentre">
            Entre em contato com os nossos consultores.
          </p>
          <BotaoSite @click="mostrar" imgw="https://aumentar-vendas.mercadobinario.com.br/imagens/WhatsApp.svg" calltoaction="Começar a conversa no WhatsApp"/>
        </div>
      </div>
    </div>
  </div>

  <div class="quartaSecao mobile">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6">
             <img id="mascara" src="@/assets/img/4/mask.webp">
        </div>
        <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <span class="anuncio">
            AQUI SEU
          </span>
          <p>
            <center><img id="degrade" src="@/assets/img/4/degrade.jpg"></center>
          </p><br><br>
          <p class="dados">
            Por meio de dados, criatividade e inteligência artificial, o Método MB aplica sete ações de marketing digital que resultam em mais conversões e aumentam a presença online da sua marca.
          </p>
          <p class="contatoentre">
            Entre em contato com os nossos consultores.
          </p>
          <center><BotaoSite @click="mostrar" imgw="https://aumentar-vendas.mercadobinario.com.br/imagens/WhatsApp.svg" calltoaction="Começar a conversa no WhatsApp"/></center>
        </div>
      </div>
    </div>
  </div>
  <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />
</template>

<script>
// @ is an alias to /src
import BotaoSite from '@/components/BotaoSite.vue'
import Popup from '@/components/Popup.vue'

export default {
  name: 'QuartaSecao',
  data: () => ({
      imgw: 'https://aumentar-vendas.mercadobinario.com.br/imagens/WhatsApp.svg',
      showDialog: false
  }),
  props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
  components: {
    BotaoSite,
    Popup
  }
}
</script>

<style scoped>


/* media css */

@media (max-width: 2000px) { 

  img.iconw {
    width: 8%;
    position: relative;
    top: 0px;
    margin-right: 9px;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    .quartaSecao {
    height: 1070px;
    background-image: url('@/assets/img/4/bg4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0px;
}

span.anuncio {
    font-size: 32px;
    line-height: 41px;
    font-weight: 500;
    text-align: right;
    display: block;
}
p.funciona {
    text-align: right;
    display: block;
    font-size: 32px;
    line-height: 45px;
    font-weight: 900;
    text-transform: uppercase;
    background-image: url('@/assets/img/4/degrade.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--branco);
}
img#funciona {
    display: block;
    justify-content: end;
    float: right;
}

p.dados {
    display: block;
    text-align: right;
}
p.contatoentre {
    text-align: right;
}
img#mascara {
    width: 100%;
    height: auto;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 391px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    justify-content: end;
    display: block;
    float: right;
    margin-top: 30px;

}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 391px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    justify-content: end;
    display: block;
    float: right;
    margin-top: 30px;
}

 }

@media (max-width: 1440px) { 

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

.quartaSecao {
    height: auto;
    background-image: url('@/assets/img/4/bg4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0px 0px 0px;
    background-color: #fff0;
    padding-bottom: 19px;
}

 }

@media (max-width: 1399px) { 
  .quartaSecao {
    height: auto;
    background-image: url('@/assets/img/4/bg4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0px 59px 0px;
    background-color: #fff0;
    padding-bottom: 82px;
}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1199.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1024px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 991.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 767.98px) { 

  .quartaSecao {
    height: auto;
    background-image: url('@/assets/img/4/bg4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 0px 0px 0px;
    background-color: #fff0;
}

img#mascara {
    width: 100%;
    height: auto;
    margin-top: -100px;
    position: relative;
}

 .desktop {
    display: none;
}

span.anuncio {
    font-size: 32px;
    line-height: 41px;
    font-weight: 500;
    text-align: center;
    display: block;
    margin-top: 50px;
}

.mobile {
  display: block;
}

p.dados {
    display: block;
    text-align: center;
}

p.contatoentre {
    text-align: center;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 391px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    justify-content: end;
    display: block;
    float: inherit;
    margin-top: 30px;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 391px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    justify-content: end;
    display: block;
    float: inherit;
    margin-top: 30px;
}

 }

@media (max-width: 575.98px) { 

  img.iconw {
    width: 8%;
    position: relative;
    top: 7px;
    margin-right: 9px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

  .quartaSecao {
    height: auto;
    background-image: url('@/assets/img/4/bg4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 0px 0px 0px;
}
img#mascara {
    width: 100%;
    height: auto;
    margin-top: -41px;
    position: relative;
}
span.anuncio {
    font-size: 32px;
    line-height: 41px;
    font-weight: 500;
    text-align: center;
    display: block;
    margin-top: 30px;
}
img#funciona {
    display: block;
    justify-content: end;
}
p.dados {
    display: block;
    text-align: center;
}
p.contatoentre {
    text-align: center;
}
button.botaoPadrao {
    background-color: #00C02A;
    width: 291px;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 12px;
    transition: .2s;
    justify-content: end;
    display: block;
    margin-top: 30px;
    float: initial;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 291px;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 12px;
    transition: .2s;
    justify-content: end;
    display: block;
    margin-top: 30px;
    float: initial;
}
img#degrade {
    width: 100%;
}
img.iconw {
    margin-top: 10px;
    width: 23px;
}
 }

@media (max-width: 360px) { 

  img.iconw {
    width: 8%;
    position: relative;
    top: 7px;
    margin-right: 9px;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}

.quartaSecao {
    height: auto;
    background-image: url('@/assets/img/4/bg4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 0px 0px 0px;
}
img#mascara {
    width: 100%;
    height: auto;
    margin-top: -41px;
    position: relative;
}
span.anuncio {
    font-size: 32px;
    line-height: 41px;
    font-weight: 500;
    text-align: center;
    display: block;
    margin-top: 30px;
}
img#funciona {
    display: block;
    justify-content: end;
}
p.dados {
    display: block;
    text-align: center;
}
p.contatoentre {
    text-align: center;
}
button.botaoPadrao {
    background-color: #00C02A;
    width: 291px;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    transition: .2s;
    justify-content: end;
    display: block;
    margin-top: 30px;
    float: initial;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 291px;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    transition: .2s;
    justify-content: end;
    display: block;
    margin-top: 30px;
    float: initial;
}
img#degrade {
    width: 100%;
}
 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}
.quartaSecao {
    height: auto;
    background-image: url('@/assets/img/4/bg4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px 0px 0px 0px;
}
img#mascara {
    width: 100%;
    height: auto;
    margin-top: -41px;
}
span.anuncio {
    font-size: 32px;
    line-height: 41px;
    font-weight: 500;
    text-align: center;
    display: block;
    margin-top: 30px;
}
img#funciona {
    display: block;
    justify-content: end;
}
p.dados {
    display: block;
    text-align: center;
}
p.contatoentre {
    text-align: center;
}
button.botaoPadrao {
    background-color: #00C02A;
    width: 291px;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    float: initial;
    display: block;
    margin-top: 30px;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 291px;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    float: initial;
    display: block;
    margin-top: 30px;
}
img#degrade {
    width: 100%;
}

img.iconw {
    width: 8%;
    position: relative;
    top: 7px;
    margin-right: 9px;
}
 }

</style>