<template>
  <div class="SextaSecao desktop">
    <div class="container-fluid">
      <div class="row">
         <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <img class="vetor" src="@/assets/img/6/icon.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'SextaSecao',
  data: () => ({
  }),
  components: {
  
  }
}
</script>

<style scoped>


/* media css */

@media (max-width: 2000px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

.SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 100px 0px 0px 0px;
    position: relative;
    z-index: 0;
    margin-top: -406px;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 803px;
    height: auto;
}
div#colorback {
    background-repeat: no-repeat;
    height: 376px;
    background-size: cover;
    padding: 0px;
}

div#colorback:hover .overlay {
    background-repeat: no-repeat;
    height: 376px;
    background-size: cover;
    background-color: #d1a4ff70;
    transition: .5s;
    width: 382px;
    padding: 0px;
}

 }

@media (max-width: 1440px) { 

  .SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 35px 0px 0px 0px;
    position: relative;
    z-index: 0;
    margin-top: -442px;
}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: auto;
}

img.multiplique {
    width: 361px;
    height: 354px;
}
div#colorback {
    background-repeat: no-repeat;
    height: 376px;
    background-size: cover;
    padding: 0px;
}

div#colorback:hover .overlay {
    background-repeat: no-repeat;
    height: 376px;
    background-size: cover;
    background-color: #d1a4ff70;
    transition: .5s;
    width: 382px;
    padding: 0px;
}

 }

@media (max-width: 1399px) { 

  img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: auto;
}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1199.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1024px) { 

   .SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 0px 0px 0px 0px;
    position: relative;
    z-index: 0;
    margin-top: -523px;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 991.98px) { 

  .SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 0px 0px 0px 0px;
    position: relative;
    z-index: 0;
    margin-top: -535px;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 768px) { 

  .SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 100px 0px;
}

img.vetor {
    padding: 50px 0px 0px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: auto;
}

  img.multiplique {
    width: 361px;
    height: auto;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

  .SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
        padding: 90px 0px 0px 0px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

img.multiplique {
    width: 100%;
}
 }

@media (max-width: 360px) { 

  .SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 0px 0px;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
img.multiplique {
    width: 100%;
}
 }
@media (max-width: 320px) { 

  .SextaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 0px 0px;
}

.desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloQuinta {
    font-size: 23px;
    line-height: 31px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
img.multiplique {
    width: 100%;
}
 }

</style>